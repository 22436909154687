<template>
    <div class="text-center">
       <label class="kt-checkbox">
           <input class="chkKelas"
                type="checkbox"
                :value="rowData"
                v-model="subVue.selectedSubTindakan"
                name="chkboxSub"
            />
            &nbsp; <span></span>
        </label>
    </div>
</template>

<script>

export default {
    props: {
        subVue: { type: Object },
        rowData: { type: Object }
    },
}
</script>

<style>

</style>
